import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { QUERY, CLEAR_ERROR, PATCH } from "@/core/services/store/request.module";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import UsersDetail from "@/view/pages/profile/Users-Detail.vue";
import ListingMixin from "@/core/plugins/listing-mixin";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
export default {
  name: "UserTableTemplate",
  components: {
    ListingTable: ListingTable,
    Dialog: Dialog,
    UsersDetail: UsersDetail,
    ListingFooter: ListingFooter
  },
  mixins: [ListingMixin],
  data: function data() {
    return {
      users: [],
      pageModule: "users-listing",
      routeAPI: "users",
      routeName: "profile.users",
      usersDetail: new Object(),
      dataLoading: false,
      updateDialog: false,
      filter: {
        search: null
      },
      timeout: null,
      timeoutLimit: 500,
      usersDetailData: null,
      formValid: true,
      formLoading: false,
      statusList: [],
      status: "all"
    };
  },
  methods: {
    clearFilter: function clearFilter(field) {
      var _this = this;

      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
      }

      this.getRows();
    },
    getRows: function getRows() {
      var _this = this;

      clearTimeout(_this.timeout);
      _this.rowData = [];
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store.dispatch(QUERY, {
          url: "users",
          data: {
            search: _this.filter.search,
            status: _this.status
          }
        }).then(function (_ref) {
          var data = _ref.data;
          _this.rowData = data.rows;
          _this.statusList = data.status_list;
        }).catch(function (error) {
          _this.logError(error);
        }).finally(function () {
          _this.dataLoading = false;
        });
      }, _this.timeoutLimit);
    },
    updateDialogConfirm: function updateDialogConfirm(row) {
      this.usersDetail = row;
      this.updateDialog = true;
    },
    updateUsersInfoDetail: function updateUsersInfoDetail(dataArr) {
      this.usersDetailData = dataArr;
    },
    updateUserRecords: function updateUserRecords() {
      var _this2 = this;

      var _this = this;

      if (!_this.$refs.userUpdateForm.validate()) {
        return false;
      }

      _this.formLoading = true;

      _this.$store.dispatch(CLEAR_ERROR, {});

      var formData = {
        first_name: _this.usersDetailData.first_name,
        last_name: _this.usersDetailData.last_name,
        phone_number: _this.usersDetailData.phone_number,
        password: _this.usersDetailData.password,
        confirm_password: _this.usersDetailData.confirm_password
      };

      _this.$store.dispatch(PATCH, {
        url: "users/" + this.usersDetail.id,
        data: formData
      }).then(function () {
        _this2.getRows();
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.formLoading = false;
      });
    }
  },
  beforeMount: function beforeMount() {
    var _this = this;

    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.status = _this.$route.query.status || _this.status;
  },
  mounted: function mounted() {
    this.getRows();
  }
};