//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import ValidationMixin from "@/core/plugins/validation-mixin";
import { GET } from "@/core/services/store/request.module";
export default {
  name: "Users-Detail",
  mixins: [ValidationMixin],
  watch: {
    usersDet: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        this.$emit("updateUsersDetail", param);
      }
    }
  },
  data: function data() {
    return {
      items: [],
      usersDet: {
        first_name: null,
        last_name: null,
        phone_number: null,
        password: null,
        confirm_password: null
      }
    };
  },
  props: {
    usersDetail: {
      type: Object,
      required: true,
      default: function _default() {
        return new Object();
      }
    }
  },
  methods: {
    getRoles: function getRoles() {
      var _this = this;

      this.$store.dispatch(GET, {
        url: "/roles"
      }).then(function (_ref) {
        var data = _ref.data;
        _this.items = data;
      }).catch(function (error) {
        _this.logError(error);
      });
    }
  },
  mounted: function mounted() {
    this.usersDet = this.usersDetail;
    this.getRoles();
  }
};