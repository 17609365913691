import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { QUERY, CLEAR_ERROR, PATCH } from "@/core/services/store/request.module";
import Dialog from "@/view/pages/partials/Dialog.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import RolesDetail from "@/view/pages/profile/Roles-Detail.vue";
export default {
  name: "UserRoleTableTemplate",
  components: {
    ListingTable: ListingTable,
    RolesDetail: RolesDetail,
    Dialog: Dialog
  },
  data: function data() {
    return {
      roles: [],
      rowData: [],
      rolesDetail: new Object(),
      pageModule: "roles-listing",
      routeAPI: "roles",
      routeName: "profile.users",
      dataLoading: false,
      updateDialog: false,
      timeout: null,
      timeoutLimit: 500,
      formValid: true,
      formLoading: false,
      usersRoleDetailData: null,
      rolestype: "Add",
      filter: {
        search: null
      }
    };
  },
  methods: {
    clearFilter: function clearFilter(field) {
      var _this = this;

      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
      }

      this.getRows();
    },
    getRows: function getRows() {
      var _this = this;

      var filter = {
        search: _this.filter.search || undefined
      };
      clearTimeout(_this.timeout);
      _this.rowData = [];
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store.dispatch(QUERY, {
          url: "/roles",
          data: {
            filter: filter
          }
        }).then(function (_ref) {
          var data = _ref.data;
          _this.rowData = data;
        }).catch(function (error) {
          _this.logError(error);
        }).finally(function () {
          _this.dataLoading = false;
        });
      }, _this.timeoutLimit);
    },
    updateDialogConfirm: function updateDialogConfirm(row, type) {
      this.rolesDetail = row;
      this.rolestype = type;
      this.updateDialog = true;
    },
    updateUsersRolesDetail: function updateUsersRolesDetail(dataArr) {
      this.usersRoleDetailData = dataArr;
    },
    updateRoleRecords: function updateRoleRecords() {
      var _this2 = this;

      var _this = this;

      if (!_this.$refs.userAddUpdateRoleForm.validate()) {
        return false;
      }

      _this.formLoading = true;

      _this.$store.dispatch(CLEAR_ERROR, {});

      var formData = {
        name: _this.usersRoleDetailData.name
      };

      _this.$store.dispatch(PATCH, {
        url: "add_update_roles/" + (this.rolesDetail.id || ""),
        data: formData
      }).then(function () {
        _this2.getRows();
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.formLoading = false;
      });
    }
  },
  mounted: function mounted() {
    this.getRows();
  }
};