//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import UserTemplate from "@/view/pages/profile/UserTableTemplate";
import UserRoles from "@/view/pages/profile/UserRoleTableTemplate";
export default {
  name: "Users",
  components: {
    ListingTemplate: ListingTemplate
  },
  data: function data() {
    return {
      userTab: null,
      usersTabs: [{
        disabled: false,
        key: "users",
        template: UserTemplate,
        title: "Users"
      }, {
        disabled: false,
        key: "usersRoles",
        template: UserRoles,
        title: "Roles"
      }]
    };
  },
  mounted: function mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "Users"
    }]);
  }
};