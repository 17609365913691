//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import ValidationMixin from "@/core/plugins/validation-mixin";
export default {
  name: "Roles-Detail",
  mixins: [ValidationMixin],
  watch: {
    rolesDet: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        this.$emit("updateRolesDetail", param);
      }
    }
  },
  data: function data() {
    return {
      items: [],
      rolesDet: {
        name: null
      }
    };
  },
  props: {
    rolesDetail: {
      type: Object,
      required: true,
      default: function _default() {
        return new Object();
      }
    }
  },
  mounted: function mounted() {
    this.rolesDet = this.rolesDetail;
  }
};